.rdtPicker {
  position: fixed;
  color: rgb(0 0 0);
}

@media only screen and (max-width: 600px) {
  .rdtPicker {
    left: 50%;
    transform: translate(-50%);
  }
}

.rdtPicker td.rdtToday:before {
  border-bottom-color: #1687af;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #1687af;
}
